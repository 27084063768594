<template>
  <div class="main" data-title="月度台账" v-title>
    <div class="table-wrapper">
      <table class="table">
        <tr>
          <th>客流性质</th>
          <th>销售顾问</th>
          <th>来访时间</th>
          <th>来访时间段</th>
          <th>离店时间</th>
          <th>接待时长</th>
          <th>客户人数</th>
          <th>客户姓名</th>
          <th>电话号码</th>
          <th>微信号</th>
          <th>信息来源</th>
          <th>是否留档</th>
          <th>是否加微信</th>
          <th>是否当月新增用户</th>
          <th>是否上月留存客户</th>
          <th>关注车型</th>
          <th>型号</th>
          <th>首次来店客户试驾</th>
          <th>再次来店客户试驾</th>
          <th>意向级别</th>
          <th>交车客户</th>
          <th>购买类型</th>
          <th>金融需求</th>
          <th>旧车评估</th>
          <th>本市及周边区</th>
          <th>战败原因</th>
          <th>登记备注</th>
          <th>跟踪备注</th>
        </tr>
        <tr :key="index" v-for="(item, index) in listData">
          <td>{{item.is_first_income == 1 ? "首次" : "再次"}}</td>
          <td>{{$store.state.userList[item.seller_uid][0]}}</td>
          <td>{{item.income_time_begin}}</td>
          <td>{{getCfgText(item.income_time_section)}}</td>
          <td>{{item.income_time_end}}</td>
          <td>{{item.income_time_duration}}</td>
          <td>{{item.income_number}}</td>
          <td>{{item.realname}}</td>
          <td>{{item.mobile}}</td>
          <td>{{item.wechat}}</td>
          <td>{{getCfgText(item.clue_source)}}</td>
          <td>是</td>
          <td>{{item.wechat == 1 ? "无" : "已加"}}</td>
          <td>{{item.is_new_income == 1 ? "是" : "否"}}</td>
          <td>{{item.is_new_income == 2 ? "是" : "否"}}</td>
          <td>{{getCfgText(item.target_model)}}</td>
          <td>{{getCfgText(item.displacement)}}</td>
          <td>{{isTrial(item)}}</td>
          <td>{{isTrial2(item)}}</td>
          <td>{{getCfgText(item.client_level)}}</td>
          <td>{{item.client_level == 1 ? "是" : "否"}}</td>
          <td>{{getCfgText(item.buy_type)}}</td>
          <td>{{getCfgText(item.credit_type)}}</td>
          <td>{{item.valuator != '' ? "是" : "否"}}</td>
          <td>{{getCfgText(item.region)}}</td>
          <td>{{item.fall_memo}}</td>
          <td>{{item.demand_memo}}</td>
          <td>{{item.trace_memo}}</td>
        </tr>
      </table>
    </div>
    <chart-search></chart-search>
    
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import ChartSearch from '@/components/chart/ChartSearch.vue'
export default {
  components: {
    ChartSearch
  },
  data() {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: []
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    isCurrFirst(item) {
      //进店时间
      let tmp_t1 = item.income_date.split('-')
      let t1 = tmp_t1[0] + '-' + tmp_t1[1]

      if (t1 >= this.key[0] && t1 <= this.key[1]) {
        return true
      } else {
        return false
      }
    },
    isTrial(item) {
      if (item.income_type == 1 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    isTrial2(item) {
      if (item.income_type == 2 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    getCfgText(key) {
      if (key == '') {
        return '-'
      }

      if (this.$store.state.cfgData[key] != undefined) {
        return this.$store.state.cfgData[key][0]
      } else {
        return '-'
      }
    },
    getNewData() {
      this.getListData()
    },
    getListData() {
      this.$axios
        .post('/statistic/data1', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids
        })
        .then(res => {
          if (res.data.code == 200) {
            this.listData = res.data.data.list
          }
        })
    },
    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  .table {
    border-collapse: collapse;
    th,
    td {
      background: #f5f7fa;
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid #ebeef5;
      padding: 7px 10px;
      font-size: 12px;
      word-break: keep-all;
      white-space: nowrap;
      text-align: center;
      &:first-child {
        border-left: none;
      }
    }

    td {
      background: #fff;
      color: #606266;
    }
  }
}
.btn-search {
  position: fixed;
  right: 20px;
  bottom: 20px;
  min-width: auto;
}
.van-popup:before{
  display: none;
}
</style>